
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserService, GaodeService, WechatService } from "src/services";
import { Toast } from "vant";
import { ILogin } from "src/model";
// import sensorsUtils from "src/utils/sensorsUtils"

@Component
export default class BestdoBenefit extends Vue {
  /**
   * appId
   * @private
   * @returns string
   */
  private get appId(): string {
    return (this.$route.query && (this.$route.query.appId as string)) || "";
  }

  /**
   * cusNo
   * @private
   * @returns string
   */
  private get cusNo(): string {
    return (this.$route.query && (this.$route.query.cusNo as string)) || "";
  }

  /**
   * 登录数据模型
   * @private
   * @returns ILogin
   */
  private modelData: ILogin = {
    code: "", // 企业编号
    account: "", // 登入账号
    password: "", // 登入密码
  };

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  protected async created(to: any) {
    try {
      this.modelData.code = localStorage.getItem("appid");
      // 获取机构信息
      this.getOrgInfo(this.modelData.code);
      this.onLogin();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 获取机构详情
   * @private
   * @returns void
   */
  private async getOrgInfo(code: string): Promise<void> {
    try {
      let { content: result } = await UserService.instance.getOrgInfo(code);
      if (result.data) {
        this.$store.dispatch("setOrgInfo", result.data);
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private async onLogin(): Promise<void> {
    try {
      let { content: result } = await UserService.instance.bestdoLogin({
        cusNo: this.cusNo,
        code: this.modelData.code,
      });
      if (result.data) {
        // 绑定神策用户数据
        // sensorsUtils.bind("identity_Id",result.data.userId +"");
        this.$store.dispatch("setUserInfo", result.data);
        this.$nextTick(() => {
          this.$router.replace({ name: "home" });
        });
      }
    } catch (err) {
      Toast(err);
    }
  }
}
